<template>
  <div class="full-width full-height bg-blue p-sm">
    <back url="/resource/index" />

    <div class="cameral-list m-b-sm m-t-sm">
      <div class="hd flex items-center justify-between">
        <!-- <span class="before-line text-white p-w-md relative">故障列表</span>  -->
        <div class="flex items-center text-lightblue flex-one">
          <span class="flex-s m-r-xs">省份：</span>
          <a-select
            placeholder="请选择省份"
            v-model="regionId"
            @change="changeProvince"
            class="m-r-sm search-mx-wd-1"
            v-show="$g.isDepart"
          >
            <a-select-option
              v-for="item in provinceList"
              :key="item.regionCode"
              :value="item.regionCode"
            >
              {{ item.alias }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">管辖单位：</span>
          <a-cascader
            :options="organizationList"
            :display-render="displayRender"
            v-model="organizationId"
            class="m-r-sm search-mx-wd-1"
            expand-trigger="hover"
            placeholder="管辖单位"
            change-on-select
            @change="selectOrganization"
          />
          <span class="flex-s m-r-xs">所属路线：</span>
          <a-select
            placeholder="请选择所属路线"
            v-model="roadId"
            class="m-r-sm search-mx-wd-1"
            @change="selectRoad"
          >
            <a-select-option
              v-for="item in roadList"
              :key="item.roadId"
              :value="item.roadId"
            >
              {{ item.roadCode + ' ' + item.roadName }}
            </a-select-option>
          </a-select>
          <span class="flex-s m-r-xs">故障类型：</span>
          <a-input
            v-model="exceptionType"
            class="m-r-sm search-mx-wd-1"
            placeholder="请输入故障类型"
          />
          <a-button
            type="primary"
            class="m-r-sm btn-search"
            @click="handleSearch"
          >
            搜索
          </a-button>
          <a-button class="btn-reset" @click="resetHandle">
            重置
          </a-button>
        </div>
      </div>
      <div class="hd3 m-t-sm">
        <div class="relative full-width">
          <a-table
            :columns="columns"
            :rowKey="(record, index) => index"
            :data-source="exceptionList"
            :pagination="paginationOpt"
            :components="resibleTableHeader"
            :scroll="scroll"
            :loading="loading"
            bordered
          >
            <template slot="customOpen" slot-scope="text, records">
              <a-tooltip placement="top">
                <template slot="title">
                  <span>查看详情</span>
                </template>
                <a-icon type="container" @click="viewDetail(records)" />
              </a-tooltip>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <a-drawer
      placement="right"
      width="40%"
      :destroyOnClose="true"
      :closable="true"
      :visible="visible"
      @close="onClose"
    >
      <div slot="title">
        故障详情<span class="desc m-l-xs" v-show="!isEdit">{{
          getStatus(recordStatus)
        }}</span>
      </div>
      <vue-scroll :ops="$root.scrollOpsY" :style="getDrawerHt()">
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">地区:</span>
          <span class="flex-one">{{ currentRecords.regionName }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">所属路线:</span>
          <span class="flex-one">{{ currentRecords.roadCode }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">摄像机名称:</span>
          <span class="flex-one ellipsis">{{ currentRecords.cameraName }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">摄像机编号:</span>
          <span class="flex-one">{{ currentRecords.cameraNum }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">管辖单位:</span>
          <span class="flex-one ellipsis">{{
            currentRecords.organizationName
          }}</span>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">故障类型:</span>
          <template>
            <span class="flex-one" v-show="!isEdit">{{
              recordExceptionType
            }}</span>
            <div class="drop relative" v-show="isEdit">
              <a-input
                v-model="recordExceptionType"
                @focus="focusHandle"
                @blur="blurHandle"
                :maxLength="10"
              ></a-input>
              <ul class="drop-list absolute l0 full-width" v-show="dropVisible">
                <li
                  v-for="item in exceptionArr"
                  class="pointer p-w-sm"
                  :key="item.id"
                  @click="selectDrop(item)"
                >
                  {{ item.text }}
                </li>
              </ul>
            </div>
          </template>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">故障原因:</span>
          <template>
            <span class="flex-one" v-show="!isEdit">{{ recordReason }}</span>
            <a-textarea
              v-model="recordReason"
              :maxLength="200"
              v-show="isEdit"
            />
          </template>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">处理方式:</span>
          <template>
            <span class="flex-one" v-show="!isEdit">{{ recordWay }}</span>
            <a-textarea v-model="recordWay" :maxLength="200" v-show="isEdit" />
          </template>
        </div>
        <div class="flex items-center m-b-sm">
          <span class="flex-s m-r-xs">处理期限:</span>
          <template>
            <span class="flex-one" v-show="!isEdit">{{
              formatDate(recordTime)
            }}</span>
            <div class="flex items-center" v-show="isEdit">
              <a-input-number
                v-model="reYear"
                :min="0"
                :precision="0"
                class="m-r-xs wd"
              />年
              <a-input-number
                v-model="reMon"
                :min="0"
                :precision="0"
                class="m-l-xs m-r-xs wd"
              />月
              <a-input-number
                v-model="reDay"
                :min="0"
                :precision="0"
                class="m-l-xs m-r-xs wd"
              />日
              <a-input-number
                v-model="reHour"
                :min="0"
                :precision="0"
                class="m-l-xs m-r-xs wd"
              />时
            </div>
          </template>
        </div>
        <div class="flex items-center m-b-sm" v-show="isEdit">
          <span class="flex-s m-r-xs">处理状态:</span>
          <a-select
            placeholder="处理状态"
            style="width: 120px;"
            v-model="recordStatus"
            @change="selectRecordStatus"
            class="m-r-xs"
          >
            <a-select-option
              v-for="item in statusList"
              :key="item.id"
              :value="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </div>
      </vue-scroll>

      <div class="flex-end flex">
        <a-button
          type="primary"
          class="m-r-xs"
          v-show="userInfo.organizationId !== '9901'"
          @click="editHandle"
          >{{ isEdit ? '保存并上报' : '修改' }}</a-button
        >
        <a-button @click="onClose">关闭</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import Back from '@/components/Back';
import { mapActions, mapState } from 'vuex';
import { CODE_OK } from '@/request/config_code';
import ResizableTable from '@/components/mixins/resizeableTable';
export default {
  name: 'ExceptionManage',
  mixins:[ResizableTable],
  data() {
    return {
      loading: false,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getExceptionList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getExceptionList();
        },
      },
      columns: [
        {
          title: '序号',
          dataIndex:'faultIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
        },
        {
          title: '故障类型',
          dataIndex: 'faultType',
          key: 'faultType',
          width:90,
          ellipsis: true,
        },
        {
          title: '地区',
          dataIndex: 'monitorRegionName',
          key: 'monitorRegionName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          key: 'organizationName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '所属路线',
          dataIndex: 'roadName',
          key: 'roadName',
          width: 120,
          ellipsis: true,
          customRender: (text, records) => {
            return `${records.roadCode} ${text}`;
          },
        },
        {
          title: '摄像机名称',
          dataIndex: 'cameraName',
          key: 'cameraName',
          ellipsis: true,
          width:220
        },
        {
          title: '故障原因',
          dataIndex: 'faultReason',
          key: 'faultReason',
          width: 120,
          ellipsis: true,
          customRender: (text) => {
            return text ? text : '--';
          },
        },
        {
          title: '处理方式',
          dataIndex: 'handleMode',
          key: 'handleMode',
          width: 100,
          ellipsis: true,
        },
        {
          title: '处理期限',
          dataIndex: 'handleTerm',
          key: 'handleTerm',
          width: 80,
          ellipsis: true,
          customRender: (text) => {
            return this.formatDate(text);
          },
        },
        {
          title: '处理状态',
          dataIndex: 'handleState',
          key: 'handleState',
          width: 100,
          customRender: (text) => {
            return text === '0'
              ? '处理中'
              : text === '2'
              ? '待处理'
              : text === '1'
              ? '已处理'
              : '--';
          },
        },
        {
          title: '更新时间',
          dataIndex: 'gmtModified',
          key: 'gmtModified',
          width: 120,
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'ope',
          key: 'ope',
          width: 60,
          className:'column-center',
          scopedSlots: { customRender: 'customOpen' },
        },
      ],
      exceptionList: [],

      regionId: undefined,
      organizationId: [],
      roadId: undefined,
      status: undefined,
      exceptionType: '',
      visible: false,
      statusList: [
        {
          id: 0,
          text: '处理中',
          value: '0',
        },
        {
          id: 1,
          text: '已处理',
          value: '1',
        },
        {
          id: 2,
          text: '待处理',
          value: '2',
        },
      ],
      currentRecords: {},
      recordOrganizationId: [],
      recordExceptionType: '',
      recordReason: '',
      recordWay: '',
      recordTime: '',
      reYear: 0,
      reMon: 0,
      reDay: 0,
      reHour: 0,
      recordStatus: 0,
      exceptionArr: [
        {
          id: 0,
          text: '网线故障',
        },
        {
          id: 1,
          text: '电路故障',
        },
        {
          id: 2,
          text: '电压不足',
        },
        {
          id: 3,
          text: '初始化失败',
        },
        {
          id: 4,
          text: '内存不足',
        },
        {
          id: 5,
          text: '设备老化',
        },
      ],
      isEdit: false,
      dropVisible: false,
      scroll: { y: window.innerHeight - 300 ,x:'100%'},
      toggleType: ''
    };
  },
  components: {
    Back,
  },
  computed: {
    ...mapState({
      provinceList: (state) => state.collect.provinceList,
      organizationList: (state) => state.collect.organizationList,
      roadList: (state) => state.collect.roadList,
      classifyList: (state) => state.collect.classifyList,
      userInfo: (state) => state.login.userInfo,
    }),
  },
  mounted() {
    this.getProvince({ regionLevel: 1 });
    this.getOrganationList();
    this.getRoadList();
    this._getExceptionList();
  },
  watch: {
    regionId(nv, ov) {
      if (nv && nv !== ov) {
        this.selectDownInfo(nv);
      }
    },
  },
  methods: {
    ...mapActions(['getProvince', 'getOrganationList', 'getRoadList']),
    formatDate(text) {
      let textArr = ['年', '月', '天', '小时'];
      if (!text) {
        return new Array(4)
          .fill(0)
          .map((item, index) => {
            return `${item}${textArr[index]}`;
          })
          .join('');
      }
      let arr = [];

      for (let i = 0; i < text.length; i += 2) {
        arr.push(parseInt(text.slice(i, i + 2)));
      }
      let newArr = [];
      arr.forEach((item, index) => {
        if (item !== 0) {
          newArr.push(`${item}${textArr[index]}`);
        }
      });
      return newArr.join('');
    },
    getHeight() {
      let ht = document.documentElement.clientHeight - 180;
      return { height: `${ht}px` };
    },
    getDrawerHt() {
      let ht = document.documentElement.clientHeight - 140;
      return { height: `${ht}px` };
    },
    _getExceptionList() {
      let data = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        faultType: this.exceptionType,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
        regionCode: this.regionId,
        roadId: this.roadId,
      };
      this.loading = true;
      this.$api.alarm.getCameraList(data).then((res) => {
        if (res.code === CODE_OK) {
          this.exceptionList = res.data;
          if (this.exceptionList) {
            this.paginationOpt.total = res.total;
          }
          this.loading = false;
        }
      });
    },
    getStatus(recordStatus) {
      return recordStatus === '0'
        ? '处理中'
        : recordStatus === '2'
        ? '待处理'
        : recordStatus === '1'
        ? '已处理'
        : '--';
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value,options) {
      this.organizationId = value;
      this.organizationCurrentId =
      this.organizationId.length > 0
        ? this.organizationId[this.organizationId.length - 1]
        : '';
      //联动
      this.toggleType = 'organization';
      if(this.organizationCurrentId === options[0].regionCode) {
        this.organizationId = [];
        return;
      }
      let tempRegionCode = this.regionId;
      this.regionId = undefined;
      if(this.$g.isDepart) {
        this.getProvince({
          organizationId: value?.length > 0 && value[value.length - 1],
          commitFlag: false,
        }).then((res) => {
          if (res.code == 200) {
            // this.regionId = res.data?.length?res.data[0].regionCode:undefined;
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionId = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
    },
    selectRecordOrganization(value) {
      this.recordOrganizationId = value;
    },
    selectStatus(value) {
      this.status = value;
    },
    selectRecordStatus(value) {
      this.recordStatus = value;
    },
    changeProvince(val) {
      this.regionId = val;
      //联动组织单位和路线
      this.getRoadList({ regionCode: val });
      this.getOrganationList({ regionCode: val });
    },
    selectRoad(e) {
      this.roadId = e;
      //联动
      this.toggleType = 'road';
      if(this.$g.isDepart) {
        let tempRegionCode = this.regionId;
        this.regionId = undefined;
        this.getProvince({ roadId: e , commitFlag: false }).then((res) => {
          // console.log(res)
          if (res.code == 200) {
            // this.regionId = res.data?.length?res.data[0].regionCode:undefined;
            let initRegionCode = res.data?.length?res.data[0].regionCode:undefined;
            let newRegionCode = res.data?.length?res.data.find(item => item.regionCode === tempRegionCode): null;
            this.regionId = newRegionCode?newRegionCode.regionCode:initRegionCode;
          }
        });
      }
    },
    selectDownInfo(nv) {
      if (this.toggleType == 'road') {
        this.getOrganationList({ regionCode: nv });
      } else if (this.toggleType == 'organization') {
        this.getRoadList({ regionCode: nv });
      }
    },
    handleSearch() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this.toggleType = '';
      this._getExceptionList();
    },
    resetHandle() {
      this.regionId = undefined;
      this.organizationId = [];
      this.roadId = undefined;
      this.exceptionType = '';
      // this.paginationOpt.current = 1;
      // this._getExceptionList();
      this.toggleType = '';
      this.handleSearch();
      this.getProvince({ regionLevel: 1 });
      this.getOrganationList();
      this.getRoadList();
    },
    viewDetail(records) {
      if(!this.hasPermission(109211121010)) {
        this.permissionWarning();
        return;
      }
      this.visible = true;
      this.currentRecords = records;
      this.recordExceptionType = records.faultType;
      this.recordReason = records.faultReason;
      this.recordWay = records.handleMode;
      this.recordTime = records.handleTerm;
      this.transfomatInput(this.recordTime);
      this.recordStatus = records.handleState ? records.handleState : '2';
    },
    transfomatInput(text) {
      if (!text) {
        this.reYear = 0;
        this.reMon = 0;
        this.reDay = 1;
        this.reHour = 0;
        return;
      }
      let arr = [];
      for (let i = 0; i < text.length; i += 2) {
        arr.push(parseInt(text.slice(i, i + 2)));
      }
      this.reYear = arr[0];
      this.reMon = arr[1];
      this.reDay = arr[2];
      this.reHour = arr[3];
    },
    onClose() {
      this.visible = false;
      this.isEdit = false;
    },
    editHandle() {
      if (!this.isEdit && !this.hasPermission(109211121011)) {
        this.permissionWarning();
        return;
      }

      if (this.isEdit) {
        if (!this.recordExceptionType) {
          this.$message.error('故障类型不能为空');
          return;
        }
        if (!this.recordWay) {
          this.$message.error('处理方式不能为空');
          return;
        }
        let obj = {
          cameraId: this.currentRecords.cameraId,
          faultReason: this.recordReason,
          faultType: this.recordExceptionType,
          handleMode: this.recordWay,
          handleTerm: this.formatTerm(
            this.reYear,
            this.reMon,
            this.reDay,
            this.reHour
          ),
          handleState: this.recordStatus,
        };
        let objParams = {
          data: obj,
          instructionsDel: {
            module: '数据看板',
            page: '故障详情',
            feature: '故障编辑',
            description: `故障编辑: ${this.currentRecords.cameraName}`,
          },
        };
        this.$api.alarm.modifyFault(objParams).then((res) => {
          if (res.code === CODE_OK) {
            this.$message.success('保存并上报成功');
            this._getExceptionList();
          }
        });
      }
      this.isEdit = !this.isEdit;
    },
    formatTerm(year, mon, day, hour) {
      let arr = [year, mon, day, hour];
      let newArr = arr.map((item) => {
        if (String(item).length < 2) {
          item = '0' + item;
        }
        return item;
      });
      this.recordTime = newArr.join('');
      return this.recordTime;
    },
    focusHandle() {
      clearTimeout(this.timer);
      this.dropVisible = true;
    },
    blurHandle() {
      this.timer = setTimeout(() => {
        this.dropVisible = false;
      }, 300);
    },
    selectDrop(item) {
      this.recordExceptionType = item.text;
    },
  },
};
</script>

<style scoped>
.desc {
  padding: 2px 4px;
  background: rgb(23, 144, 243);
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
}
.drop-list {
  background: #475f8d;
  top: 100%;
  color: #fff;
  z-index: 999;
}
.drop-list li {
  line-height: 28px;
}
.wd {
  width: 50px;
}
</style>
